import React, { useMemo } from 'react';
import LogoSliderComponent from '../../LogoSlider';
import style from '../ListPresentation.module.scss';
import { twMerge } from 'tailwind-merge';

function getAutoScrollSpeedInSec(scrollSpeed) {
  switch (scrollSpeed) {
    case 2:
      return '7.5s';
    case 1:
      return '15s';
    case 0.5:
      return '22.5s';
    default:
      return '30s';
  }
}

export default function LogoSliderRenderer({
  cardFrameConfig,
  listPresentationConfig,
  gridGapAxis,
  sectionContainerRef,
  oneGridCellGeometry,
  isMobile,
  sectionColors,
  itemCountTotal,
  isSliderFullWidth,
}) {
  const { scrollSpeed } = cardFrameConfig;

  const scrollSpeedInSec = useMemo(
    () => getAutoScrollSpeedInSec(scrollSpeed),
    [scrollSpeed]
  );

  function renderLogoSliderComp() {
    if (
      cardFrameConfig?.autoScroll &&
      listPresentationConfig?.layoutType === 'carousel'
    ) {
      const groupWidth =
        oneGridCellGeometry.width * itemCountTotal +
        itemCountTotal * gridGapAxis.columnGap;

      return (
        <div
          style={{
            maxWidth: !isSliderFullWidth
              ? !isMobile
                ? sectionContainerRef.current?.clientWidth - 80
                : sectionContainerRef.current?.clientWidth - 40
              : sectionContainerRef.current?.clientWidth,
          }}
          className={twMerge(style.carousel, 'tw-pb-[10px] tw-pt-[35px]')}
        >
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration: scrollSpeedInSec,
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration: scrollSpeedInSec,
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration: scrollSpeedInSec,
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
        </div>
      );
    }
    return cardFrameConfig?.multiColumnData?.map((columnData, index) =>
      columnData?.isVisible ? (
        <LogoSliderComponent
          key={index}
          index={index}
          columnData={columnData}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          sectionColors={sectionColors}
          multiColumnData={cardFrameConfig?.multiColumnData}
        />
      ) : null
    );
  }

  return renderLogoSliderComp();
}
