import classNames from 'classnames';
import { getCurrentClass } from '../CardFrameCollection/utils';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useSSRSelector } from 'src/redux/ssrStore';
import { btnActionHandler } from '../../ModernTheme/utils/btnUtils';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';

const LogoSliderComponent = (props) => {
  const {
    listPresentationConfig,
    cardFrameConfig,
    oneGridCellGeometry,
    columnData,
    index,
    sectionColors,
  } = props;
  const {
    widgetContextState,
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();
  const isMobile = isMobileDevice();

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  const { imageCards = {} } = (storeData?.theme as any)?.style || {};

  const { widgets, activeWidgetId } = widgetContextState;

  const activeWidget = widgets?.[activeWidgetId];
  const activeWidgetProps = activeWidget?.props;

  const { textAlignment, isGreyScaleLogo } = cardFrameConfig;
  const { appearance, borderColor, backgroundColor } = listPresentationConfig;
  const isSelected =
    activeWidgetProps?.subWidgetIndex === index &&
    listPresentationConfig?.type === activeWidgetProps?.listPresentation?.type;

  function renderImage(data, index) {
    const {
      imageDetail: { url },
    } = data;
    const actionObj = {
      type: data?.redirectionType || '',
      handler: data?.redirectUrl || '',
      newTab: data?.isOpenInNewTab || '',
    };

    return (
      <div
        onClick={() => btnActionHandler(actionObj, () => {})}
        className={classNames(
          `tw-relative tw-flex-shrink-0 tw-items-center tw-overflow-hidden`,
          appearance !== 'card' && !isMobile
            ? contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)]
            : ''
        )}
        style={{
          width: `${oneGridCellGeometry?.width - (appearance === 'card' ? 16 : 16)}px`,
          height: `${oneGridCellGeometry?.height - (appearance === 'card' ? 16 : 12)}px`,
          justifyContent: textAlignment,
          borderRadius: imageCards?.style?.borderRadius?.replace('!important', ''),
          background: appearance !== 'card' ? sectionColors?.background : backgroundColor,
        }}
      >
        <img
          src={url || '/assets/images/richTextWithImage.png'}
          style={{ filter: isGreyScaleLogo && !isMobile ? 'grayscale(100%)' : '' }}
          className={classNames(
            `removeGlobalOverride tw-absolute tw-h-[100%] !tw-max-h-[120px] tw-w-[100%] tw-transition-all tw-duration-500 ${url ? 'tw-object-contain' : 'tw-object-cover'} tw-p-[4px]
             ${isGreyScaleLogo && !isMobile ? 'hover:!tw-grayscale-0' : ''}`
          )}
        />
      </div>
    );
  }

  function onNavigate(e, index: number) {
    if (activeWidgetId) {
      handleWidgetPropChange(
        {
          subWidget: '',
          subWidgetIndex: -1,
        },
        activeWidgetId
      );
      setTimeout(() => {
        handleWidgetPropChange(
          {
            subWidget: 'logoSlider',
            subWidgetIndex: index,
            cardFrame: activeWidgetProps?.cardFrame,
          },
          activeWidgetId
        );
      }, 10);
    }
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onNavigate(e, index);
        return;
      }}
      className="tw-flex"
    >
      <div
        style={{
          ...(appearance === 'card'
            ? {
                border: isSelected
                  ? '1px solid #2B74D8'
                  : `1px solid ${borderColor || 'black'}`,
                background: backgroundColor,
                padding: '8px',
              }
            : { padding: '8px' }),
          ...(appearance !== 'card' && isSelected ? { border: '1px solid #2B74D8' } : {}),
          width: oneGridCellGeometry?.width,
        }}
        className={classNames(
          appearance === 'card' && !isMobile
            ? contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)]
            : '',
          `imgWrapperGlobalStyle tw-relative tw-flex tw-cursor-pointer tw-gap-[20px]`
        )}
      >
        {isSelected && (
          <div className="tw-absolute tw-left-0 tw-top-[-27.5px] tw-w-[108px] tw-bg-[#2F80ED] tw-px-[12px] tw-py-[4px] tw-text-center">
            <span className="tw-text-[14px] tw-font-normal tw-text-[#fff]">{`Logo ${activeWidgetProps?.subWidgetIndex + 1}`}</span>
          </div>
        )}
        {renderImage(columnData, index)}
      </div>
    </div>
  );
};

export default LogoSliderComponent;
